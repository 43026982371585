import "core-js/modules/es.array.push.js";
import { Toast } from 'vant';
import { getElevatorList } from '../../api/public';
export default {
  data() {
    return {
      loading: false,
      refreshing: false,
      finished: false,
      value: '',
      form: {
        limit: 10,
        page: 1
      },
      elevatorData: []
    };
  },
  created() {
    this.onRefresh();
  },
  methods: {
    onSearch() {
      try {
        this.onRefresh();
      } catch (error) {
        console.info(error);
      }
    },
    onCancel() {
      Toast('取消');
    },
    onClickLeft() {
      this.$router.push('home');
      ///console.log(11)
    },

    onSubmit(item) {
      this.$router.push({
        path: 'detailsDt',
        query: {
          id: item.id
        }
      });
    },
    async onRefresh() {
      this.form.page = 1;
      this.elevatorData = [];
      this.finished = false;
      //this.loading = true;
      this.onLoad();
    },
    async onLoad() {
      this.loading = true;
      this.form.name = this.value || null;
      console.info(this.form);
      getElevatorList(this.form).then(response => {
        if (this.refreshing) {
          setTimeout(() => {
            this.refreshing = false;
          }, 1000);
          //this.loading = false;
        }

        this.elevatorData = this.elevatorData.concat(response.data);
        if (response.data.length < this.form.limit) {
          this.finished = true;
        } else {
          this.form.page++;
          this.finished = false;
        }
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }).catch(error => {
        this.finished = true;
        this.loading = false;
      });
    }
  }
};